import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { basePageWrap } from '../BasePage';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

import VideoTextCarouselHero from '../../components/VideoTextCarouselHero/VideoTextCarouselHero';
import WhatWeOfferModule from '../../components/WhatWeOfferModule';
import Footer from '../../components/Footer';
import ButtonPrimary from '../../components/ButtonPrimary';
import classNames from 'classnames';
import Image from 'next/image';
import Globe from '../../public/img/globb.webp';
import StreamField from '../../components/StreamField';

import s from './HomePage.module.scss';

const HomePage = ({ homeHero, whatWeOffer, siteSetting, insights, blocks }) => {
  const [ref, isVisible] = useIntersectionObserver();
  const titleClasses = classNames(s.Title, 'grid', { [s.Visible]: isVisible });
  const titleMobileClasses = classNames(s.TitleMobile, {
    [s.Visible]: isVisible,
  });

  const contentClasses = classNames(s.TextContent, 'grid', {
    [s.Visible]: isVisible,
  });
  return (
    <div className={s.Root}>
      <VideoTextCarouselHero {...homeHero} />
      <div className={s.Content}>
        <WhatWeOfferModule {...whatWeOffer} modifier="HomePage" />

        <div ref={ref} className={s.InsightsWrapper}>
          <h2 className={titleMobileClasses}>
            <span className={titleClasses}>
              <span className={s.TitleContainer}>
                <span className={s.TitleTop}>{insights?.titleTop}</span>{' '}
                <span className={s.TitleBottom}>{insights?.titleBottom}</span>
              </span>
            </span>
          </h2>
          <h2 className={s.TitleDesktop}>
            <span className={titleClasses}>
              <span className={s.TitleTop}>{insights?.titleTop}</span>
              <span className={s.TitleBottom}>{insights?.titleBottom}</span>
            </span>
          </h2>
          <div className={contentClasses}>
            <p className={s.Text}>{insights?.preamble}</p>
          </div>
          <div className={'grid'}>
            <div className={s.ButtonWrapper}>
              <ButtonPrimary
                href={insights?.href}
                modifiers={['InlineFlex', 'IconHoverDown']}
                icon={'icon-arrow-right-white'}
                asLink>
                Discover Stockholm Insights
              </ButtonPrimary>
            </div>
          </div>
          <div className={'grid'}>
            <div className={s.ImageWrapper}>
              <span className={s.BlobLeft} />
              <span className={s.BlobRight} />
              <Image
                src={Globe}
                fill
                style={{ objectFit: 'contain' }}
                alt="Illustration of the globe"
              />
            </div>
          </div>

          <StreamField blocks={blocks} from="HomePage" />
        </div>

        <footer className={s.FooterWrapper}>
          <div className={`${s.FooterHr} grid`} />
          <Footer
            cookieConsent={siteSetting?.cookieConsent}
            {...siteSetting?.footer}
          />
        </footer>
      </div>
    </div>
  );
};

HomePage.defaultProps = {
  title: '',
};

HomePage.propTypes = {
  title: PropTypes.string.isRequired,
};

export default basePageWrap(HomePage, { noBasePageFooter: true });
